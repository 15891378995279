.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100dvh;
  background-color: #f8f9fd;
}

/* input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
} */

.showingSuggestions {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  background-color: white;
  transition: position 0.5s ease-in-out;
}

.showingSuggestions input {
  margin-bottom: 0;
}

.showingSuggestions ul {
  padding: 0;
  width: 250px;
  max-height: 500px;
  overflow-y: scroll;
  list-style: none;
  background-color: rgba(230, 230, 230);
  margin-top: 0;
}

.showingSuggestions li {
  padding: 10px;
  border-bottom: solid 1px #ccc;
  cursor: pointer;
  width: 230px;

}

.showingSuggestions li:hover {
  background-color: var(--theme-color-transparent);
  color: white;
  font-weight: 700;
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

.missingInput {
  border: solid red 1px !important;
}


/* For Webkit browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


/* Hide scrollbar for Firefox */
body {
  scrollbar-width: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}

.logoContainerUnregisteredInstallation {
  background-color: var(--theme-color);
  height: 50px;
  width: 100%;
  padding: 20px;
  width: calc(100% - 40px);
  margin-bottom: 30px;
}

.logoContainerUnregisteredInstallation img {
  background-color: var(--theme-color);
  height: 40px;
  width: 100%;
}


.logoContainer {
  background-color: var(--theme-color);
  width: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.logoContainer img {
  width: 100%;
  height: 100%;
  margin-left: 20px;
}

.languagePicker {
  color: white;
  font-size: small;
  font-weight: 700;
  padding: 5px;
  cursor: pointer;
  background-color: var(--theme-color);
}

.languagePicker .dropdown {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
}

.languagePicker .dropdown .options {
  background-color: var(--theme-color-transparent);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  cursor: pointer;

}

.languagePicker .dropdown .option {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--theme-color-transparent);
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.logoContainer a {
  display: flex;
  justify-content: center;
  align-items: center;

}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px
}

:root {
  --theme-color: rgba(19, 40, 58, 1);
  --theme-color-transparent: rgba(67, 84, 97, 1);
  --theme-color-variation1: #0b579e;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 90;
  font-style: italic;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100dvh;
  background-color: #ffffff;
}

.projectsForm {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 160px);
  align-items: center;
  padding: 0 10px;
}

.projectRow button {
  font-size: small;
}

.projectRow {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 7px 24px #37474f4d;
  margin: 10px;
  border: 1px transparent;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none !important;
  width: 100%;
  font-size: small;
  font-weight: 700;
}

.installationsLink {
  text-decoration: none !important;
  background-color: #0b579e;
  color: white;
  width: 50%;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.project:hover {
  background-color: var(--theme-color-transparent);
  color: white;
  font-weight: 700;
}

.project-image {
  width: 25%;
  height: 100%;
  background: linear-gradient(135.95deg, #0b579e 8.39%, #13283b 90.83%);
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px transparent;
  border-radius: 5px;
}

.project-info {
  margin-left: 20px;
  margin-top: 10px;
}

.project-address {
  font-weight: 700;
}

.projectHeader {
  margin-left: 10px;
  margin-right: 10px;
  font-size: large;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchIconContainer {
  background: linear-gradient(135.95deg, #0b579e 8.39%, #13283b 90.83%);
  border-radius: 5px;
  padding: 5px 10px;
}

.searchIcon {
  color: white;
}

.project-installation-search-container {
  width: 100%;
  border-radius: 5px;
}

.project-bottom-row {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.project-approval-stamp {
  width: 50px;
}

.project-link-container {
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: flex-end;
  margin-top: 10px;
}

.project-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
}

.projectActions button {
  margin: 10px;
}

.fileLink {
  text-decoration: none !important;
  width: 100%;
}

.fileNameContainer {
  width: 100px;
  flex-grow: 1;
  max-width: 600px;
  padding-left: 10px;
}

.fileRow:hover {
  background-color: var(--theme-color-transparent);
  color: white;
  font-weight: 700;
}

.quantityInputProductData {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.quantityInputProductData img {
  width: 80%;

}

.quantityInputProductInfo {
  display: flex;
  flex-direction: row;
}

.quantityInputProductInfoId {
  margin-right: 10px;
}

.quantityInputQtyContainer {
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: center;
  align-items: center;
}

.quantityInputQty {
  margin-left: 50px;
  width: 15%;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.componentContainer {
  height: calc(100dvh - 50px) !important;
}

.installationsActions button {
  margin: 10px;
}

.nav-buttons {
  background-color: var(--theme-color);
  /* width: 100vw; */
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.nav-menu {
  color: white;
  padding-left: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: smaller;
  font-weight: 700;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.nav-link:hover {
  color: var(--theme-color);
  background-color: white;
  text-decoration: underline;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: smaller;
  font-weight: 700;
  cursor: pointer;
}


nav {
  display: flex;
  background-color: var(--theme-color);
  justify-content: space-between;
}

footer {
  display: flex;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*overflow: hidden;*/
  height: 100dvh !important;
}

:root {
  height: 100dvh;
  /*overflow: hidden;*/
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.navigateSpan {
  cursor: pointer;
}

.navigateSpan:hover {
  color: white;
  font-weight: 700;
  text-decoration: underline;
}

.installationRow {
  font-size: small;
}

.installationRow button {
  font-size: small;
}

.languagePicker {
  display: none;
}

.for-pdf {
  font-size: small;
  height: 3000px;
  position: fixed;
  justify-content: none;
  left: -10000px;
  max-height: calc(100dvh - 100px) !important;
  /* max-height:100000px !important; */
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}

.buttonContainer {
  flex-grow: 1;
}

.fileIcon {
  /* width:35px; */
  color: white;
  background-color: #0b579e;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

/* @media only screen and (max-width: 799px) {
  .datetime {
    display: none;
  }

  .installationRow {
    font-size: small;
    width: calc(100% - 30px) !important;
    font-weight: 700;
  }

  .installationRow button {
    font-size: small;

  }

  .projectRow button {
    font-size: small;

  }

  .fileRow {
    font-size: 14px;
    font-weight: 500;
  }

  .fileRow button {
    font-size: small;
  }
} */

.quantityModal {
  overflow: hidden;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantityInputContainer {
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  border: solid 1px #ccc;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.productListRow {
  display: flex;
  border-bottom: solid 1px #ccc;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.productList :hover {
  border: solid 1px #01d28e !important;
}

/*.registerFormContainer{
  width:100vw;
  position:fixed;
  top:0px;
  height: 100dvh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/gkvlchkxcr/imageFileData/MD1vED?pubver=0) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}*/



.unregisteredInstallationFormContainer {
  width: 100vw;
  position: fixed;
  top: 0px;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/gkvlchkxcr/imageFileData/MD1vED?pubver=0) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.loading-container {
  /* other styles... */
  opacity: 0;
  /* default to invisible */
  transition: opacity 0.2s ease-in-out;
  /* transition effect */
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2000;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0px;
}


.loading-container.visible {
  opacity: 1;
  /* fully visible when the class is added */
  pointer-events: auto;
}

.loading-container.hidden {
  opacity: 0;
}

.noFilesMessage {
  padding: 25px;
  text-align: center;
}

.loading-animation {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid var(--theme-color);
  /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button {
  width: 100%;
  padding: 0.5rem;
  /* height:50px; */
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
  background-color: var(--theme-color-variation1);
  color: white;
  border: none;
  font-family: 'Poppins', sans-serif;
}

button:hover {
  border-radius: 5px;
}



input {
  width: 230px;
  border-radius: 0px;
  background-color: lightgray;
  color: black;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
}



.registerError {
  color: red;
}

.requestPasswordContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}

.checkEmailMessage {
  padding: 20px;
}

/*.registerFormContainer input{
  width:240px;
  border-radius: 0px;
  background-color:lightgray;
  color:black;
  border:none;
  margin-bottom: 10px;
  padding:10px;
}*/


input {
  height: 25px;
  border-radius: 5px;
  border: 1px gray solid;
  margin: 5px;
  font-family: 'Poppins', sans-serif;
}

/*.registerFormContainer fieldset{
  border:none;
}*/


.registerLink {
  color: white;
  position: fixed;
  bottom: 0;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.registerLinkLogin {
  color: white;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*.registerForm{
  width:300px;
  padding-bottom:30px;
  background-color:white;

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 40px 4px #111118;
}*/

.installationFilesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 555px);
  box-sizing: border-box;
  padding: 20px;
}

.installationFilesContainerM {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 505px);
  box-sizing: border-box;
  padding: 20px;
}

.installationsFilesContainerL {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 385px);
  box-sizing: border-box;
  padding: 20px;
}

.installationsFilesContainerXL {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 290px);
  box-sizing: border-box;
  padding: 20px;
}

.installationsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 160px);
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 20px;
}

.installationIDContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border-top: solid 1px white; */
  font-weight: 700;
  background-color: #f3f3f3;
  font-size: small;
  width: 100vw;
  text-align: center;
  color: #37474f;
  padding: 5px;

}

.installation-info-mapIcon {
  width: 15%;
  color: #0b579e;
}

.installation-info-editIcon {
  width: 15%;
  color: #0b579e;
}

.installations-searchIcon {
  width: 15%;
  color: #0b579e;
  background-color: transparent;
}

.installation-info-container {
  display: flex;
  flex-direction: column;
}

.inspectionContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
  border-bottom: 1px solid lightgray;
  widows: calc(100% - 20px);
}

.inspectionDateLabel,
.inspectionDate {
  margin: 10px 0px;
  font-size: smaller;
}



.inspectionSignContainer {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  /* width: calc(100% - 20px); */
  /* border-bottom: 1px solid lightgray; */
  justify-content: center;
}

.installationControlBtnGroup {
  margin-left: 10px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
}

.installationControlsBtn {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: small;
  font-weight: 500;
  background-color: #0b579e;
}

.installationControlRegisterBtn {
  font-size: small;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: #0b579e;
}

.installationInspectionRegisterBtnContainer {
  margin-right: 10px;
}

.inspectionSign {
  /* height: 55px; */
  /* margin: 0px 10px 0px 35px; */
  width: 35%;
}

.inspectionStatus {
  display: flex;
  align-items: center;
  font-size: smaller;
}

.Approved {
  color: #009460;
}

.NotApproved {
  color: red;
}

.inspectedByLogo {
  width: 50%;
  height: 55px;
}

.inspectedByNameAndCompany {
  font-size: smaller;
}

.installationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filePreviewImg {
  display: none;
}

.installationActionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  grid-auto-rows: auto;
}

.installationsActionsContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  width: calc(100% - 20px);
  grid-auto-rows: auto;
  margin: 0px 10px;
}

.installationActionsContainer button {
  font-size: small;
  padding: 0.3rem;
}

.installedByContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  color: #37474f;
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
}

.installedByLabel {
  /* margin-top: 10px; */
  font-weight: 500;
  font-size: small;
}

.installedByLogo {
  /* background-color: #0b579e; */
  border-radius: 5px;
  /* margin-right: 20px; */
  width: 60%;
  height: 55px;
}

.installedByNameAndCompany {
  /* width: 50%; */
  /* margin-left: 20px; */
  font-size: smaller;
}

.installedBy {
  /* margin-bottom: 10px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.floatingMsg.error {
  background-color: rgba(255, 0, 0, 0.98);
  color: white;
  z-index: 100;
}

.floatingMsg.fadeOut {
  opacity: 0;
  /* pointer-events: none; */
}

.floatingMsg {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 0 40px 4px #111118;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.installationActionsContainer button {
  margin-left: 5px;
  margin-right: 5px;
  width: initial;
}

.installationFinishBtn {
  background-color: #0B9E59;
  grid-column: 1 / -1;
}

.fileRow {
  padding: 12px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-bottom: solid 1px #ccc; */
  border-radius: 5px;
  box-shadow: 0px 7px 24px #37474f4d;
  background-color: white;
  margin: 3px;
  color: #13283b;
  font-size: small;
}

.fileRow a {
  padding: 20px;
}


.userDialogContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 39, 58, 0.85);
  color: #13273a !important;
  z-index: 1000;
}

.installationRow {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 7px 24px #37474f4d;
  margin: 10px 0;
  border: 1px transparent;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none !important;
  /* width: calc(100% - 30px); */
  width: 100%;
  ;
}

.installationLink {
  text-decoration: none !important;
  background-color: #0b579e;
  color: white;
  width: 100%;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.installationRow:hover {
  background-color: var(--theme-color-transparent);
  color: white;
  font-weight: 700;
}

.installationId {
  width: 200px;
}

.name {
  width: 200px;
}

.modifiedDateTime {
  font-size: small;
}

.createdDateTime {
  font-size: small;
}

.modifiedDateTimeLabel {
  font-size: small;
  padding-right: 5px;
}

.createdDateTimeLabel {
  font-size: small;
  padding-right: 5px;
}

.scannerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  flex-direction: column;
}

.scannerExplanation {
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 20px;
  font-size: x-large;
}

#scanner {
  width: 100vw;
  max-width: 100vh;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scannerCancelButton {
  position: fixed;
  bottom: 50px;
}

.confirmDialog,
.infoDialog {
  /* width: 250px; */
  width: 300px;
  /* height:200px; */
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  border-radius: 8px;
  border: solid 1px #ccc;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

.userInputDialogConfirm {
  margin-top: 20px !important;
}

.confirmDialog button,
.infoDialog button {
  margin: 5px;
}

.confirmDialog input,
.infoDialog input {
  margin: 5px;
  width: 100%;
}

.deleteButton {
  background-color: #f93e3e;
  width: 100px;
  border-radius: 5px;
  margin-left: 10px;
}

.smallActionButton {
  width: 100px;
  border-radius: 5px;
  margin-left: 10px;

}

/* .productListContainer .createPDFBtn {
  margin: 20px;
  width: 50%;
  background-color: #0b579e;
} */

.productListContainer .createPDFBtnContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.productList {
  max-height: calc(100% - 100px);
  overflow-y: scroll;
}

.activeProduct {
  background-color: var(--theme-color-transparent);
  color: white;
  font-weight: 700;
}

.productListRow img {
  width: 60px;
  height: 60px;
}

.productListRow div {
  /*padding:10px;
  width:200px;*/
  overflow: hidden;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.wizardContainer {
  border-radius: 10px;
  width: 600px;
  max-width: 90vw;
  position: fixed;
  /* transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 40px 4px #111118;
  justify-content: space-between;
  background-color: white;
  max-height: 95dvh;
  overflow-y: scroll;
  min-height: 410px;
  color: #37474F;
  /*overflow-y: hidden;*/
}

.wizardHeaderContainer {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.wizardHeader {
  font-size: larger;
  font-weight: 500;
}

.wizardPageNumber {
  color: #c7c7cc;
}

.wizardContentContainer {
  display: flex;
  overflow-y: scroll;
}

.wizardPage {
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.wizardPageTitle {
  display: flex;
  align-items: center;
}

.wizardPageTitle h3 {
  margin: 0;
  /* border-bottom: 1px solid lightgray; */
  font-size: large;
  font-weight: 500;
}

.wizardInfoBtnContainer {
  margin-left: 10px;
}

.wizardPage textarea {
  resize: none;
  font-family: inherit;
  font-size: small;
  width: calc(100% - 21px);
  margin: 0;
  padding: 10px;
  margin-top: 20px;
  border: solid 1px #707070;
  border-radius: 5px;
  height: 80px;
}

.wizardPage input[type="radio" i] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.wizardButtonsContainer {
  /* border-top: solid 1px #ccc; */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wizardButtonsContainer button {
  width: 100px;
  margin: 3px;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.cameraContainer {
  background-color: black;
  position: fixed;
  width: 100vw;
  height: 100dvh;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 3000;
}

.cameraContainer video {
  width: 100vw;
  height: 80dvh;
  object-fit: cover;
}

.cameraContainer .cameraButtonsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.cameraContainer .cameraButtonsContainer button {
  margin: 10px;
}

.wizardImage {
  /* padding:50px; */
  display: flex;
  justify-content: center;
}

/* .wizardImage img{
    border-radius:5px;
    width:100%;
    object-fit: cover;
} */

.wizardButtonArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wizardButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.wizardButtonContainer button {
  background-color: white;
  color: #0b579e;
  border: 1px solid;
}

.wizardBackground {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.wizardPage label.selected {
  font-weight: bold;
}

.imageCarouselList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.imageCarouselList img {
  padding: 5px;
  min-width: 50px;
  max-width: 100px;
  width: 20%;
  object-fit: cover;
}

/*.imageCarouselList img.selected {
  background-color: var(--theme-color-transparent);
}*/

.imageCarouselContainer {
  padding: 10px;
  margin-bottom: 10px;
}

.imageCarouselContainer .imagePreview.fullScreen {
  height: 100dvh;
  width: 100vw;
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.imageCarouselContainer .imagePreviewCaption {
  font-style: italic;
  font-size: xx-small;
}

.imageCarouselItem :active {
  border: 1px solid;
  border-color: #0b579e;
}

.imagePreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.imageCarouselContainer .imagePreview.fullScreen .imagePreviewCaption {
  color: white;
  padding: 20px;
}

.imageCarouselContainer .imagePreview.fullScreen {
  height: 100dvh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
}

.imageCarouselItem {
  display: flex;
  flex-direction: column;
}

.loginFormContainer,
.registerFormContainer {
  width: 100vw;
  position: fixed;
  top: 0px;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(https://cdn.animaapp.com/projects/655f0b370145539dc9479996/releases/655f0ba1e082f52c93ef54d0/img/rectangle-325@1x.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-y: scroll;
}

.createInstallationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #13273a !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.85;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}

.registerInstallationFormContainer {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  place-items: center;
}

.approveInstallationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #13273a;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.85;
  height: calc(100vh - 50px);
}

/*.createInstallationContainer form{
    overflow-y:scroll;
    max-height:calc(100% - 80px);
    max-width: 500px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 40px 4px #111118;
    justify-content: center;
    align-items: center;
}*/

/*.loginFormContainer,
.registerFormContainer,
.createInstallationContainer,
.approveInstallationContainer button {
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #0b579e;
  font-family: "Poppins", Helvetica;
  font-size: medium;
  font-weight: 400;
}*/

.loginForm {
  width: 300px;
  border-radius: 10px;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px #111118;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding: 0 10px;
}

.registerForm {
  width: 300px;
  border-radius: 8px;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px #111118;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding: 0 10px;
  /* top: 0;
  position: fixed; */
}

.registerInstallationForm {
  width: 350px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 50px; */
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: 0px 7px 24px #37474f6b;
  color: #37474f;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
}

.register-installation-title {
  margin: 5px 0;
}

.approveInstallationForm {
  width: 350px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: 0px 7px 24px #37474f6b;
  color: #37474f;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
}

.logoContainerLogin {
  background-color: var(--theme-color);
  /* height: 50px; */
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoContainerLogin img {
  background-color: var(--theme-color);
  height: 40px;
  width: 100%;
  margin-top: 10px;
}

.input-default {
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 5px 0;
}

.input-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.approve-installation-input {
  /*padding: 2rem;*/
  margin: 0.2rem;
  /*padding-bottom: rem;*/
  width: calc(100% - 50px);
  border-radius: 8px;
  background-color: transparent;
}

.div {
  color: #37474f;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: 12px;
  top: 10px;
  white-space: nowrap;
  margin-top: 10px;
  margin-left: 10px;
}

.input-style {
  font-family: "Poppins", Helvetica;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;

}

.input-style::placeholder {
  color: #c7c7cc;
}

.login-button,
.register-button {
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
  border-radius: 8px;
}


.registerUserInputDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

/*.registerUserInputDiv input{
    padding-left:5px !important;
    padding-top:0px !important;
    padding-bottom:0px !important;
    padding-right:0px !important;
    width:calc(100% - 5px);
    margin:0;
  }*/

.input-comments {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  height: 85px;
}

.formButtonGroup {
  width: calc(100% - 50px);
  /* display: flex;
  flex-direction: row; */
  margin-top: 20px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.formButtonGroup>*:last-child:nth-child(odd) {
  grid-column: 1 / -1;
  /* This makes the item span all columns */
}

.nextButton {
  width: 100%;
  /* position: relative; */
  /* left: 20%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
}

.backButton {
  width: 100%;
  /* position: relative; */
  /* margin-left: 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
}

.tempSaveButton {
  width: 100%;
  /* position: relative; */
  /* left: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
}

.saveButton {
  width: 100%;
  /* position: relative; */
  /* left: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
}

.iconRight {
  margin-left: 15px;
}

.createInstallationInstallationIdContainer,
.approveInstallationInstallationIdContainer {
  display: flex;
  /* position: absolute; */
  top: 50px;
  background-color: #f3f3f3;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.createInstallationInstallationId,
.approveInstallationInstallationId {

  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 30px;
  position: relative;
  top: 5%;
}

.approve-checkbox {
  justify-content: center;
  display: flex;
  align-items: center;
  border: solid;
  width: 15%;
  border-width: 1px;
  border-radius: 3px;
  color: #0b579e;
  border-color: black;
  /* margin-top: 5px; */
  /* margin-bottom: 10px; */
  padding: 0.2rem;
}

.approve-button {
  background-color: white !important;
  border: solid;
  border-color: #0b579e;
  border-width: 1px;
  width: 80%;
  color: #0b579e;
  margin-right: 20px;
}

.bomGroup,
.controlGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.iconHidden {
  color: transparent;
}

.approve-installation-text {
  font-size: 16px;
  margin: 20px;
}

.approve-steps {
  margin-left: 0px !important;
  overflow-wrap: break-word;
  line-height: normal;
  white-space: unset;
}

.iconLeft {
  margin-right: 15px;
}

.productListHeaderContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  margin: 20px;
}

.productListHeader {
  font-size: larger;
  font-weight: 500;
}

.productListInfo {
  font-size: small;
}

.productList {
  /*max-height: calc(100% - 100px);*/
  /*overflow-y: scroll;*/
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.upload-icon-container {
  display: flex;
}

.product-list-table-header {
  display: flex;
  flex-direction: row;
  font-size: x-small;
  font-weight: 500;
  margin-right: 20px;
  margin-left: 20px;
  border-bottom: 1px solid;
}

.product-list-table-header-id {
  margin-left: 10px;
  width: 20%;

}

.product-list-table-header-name {
  margin-left: 10px;
  width: 75%;
}

.product-list-table-header-qty {
  margin-left: 10px;
  width: 20%;
}

.product-list-table-id {
  width: 20%;
}

.product-list-table-name {
  width: 75%;
}

.product-list-table-qty {
  width: 20%;
  border: 1px solid;
  border-radius: 5px;
  justify-content: center !important;
  height: 40px;
}

.product-list-table-header-empty {
  width: 60px;
  min-width: 60px;
}

.product-list-table-container {
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  overflow-y: scroll;
  max-height: calc(100% - 270px);
  border-bottom: 1px solid;
}

.quantityModalOverlay {
  position: fixed;
  top: 89px;
  background-color: rgba(19, 39, 58, 0.85);
  width: 100%;
}

.installations-image {
  width: 20%;
  /* height: 100%; */
  /* background: linear-gradient(135.95deg, #0b579e 8.39%, #13283b 90.83%); */
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px transparent;
  border-radius: 5px;
  margin-left: 10px;
  max-height: 80px;
  padding: 5px 0;
}

.installations-info {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.installations-address {
  font-weight: 100;
}

.installations-bottom-row {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  /* margin-top: 10px; */
}

.installations-approval-stamp {
  width: 50px;
}

.installations-link-container {
  display: flex;
  width: 52%;
  /* justify-content: right; */
  align-items: flex-end;
}

.installations-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.installation-address {
  font-weight: 100;
}

.deleteFilesBtn {
  background-color: white !important;
  color: red;
  border: 1px solid;
  border-color: #0b579e;
}

.fileRowContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.checkbox-container {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileForm {
  width: 100%;
}

/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.checkbox-container .custom-checkbox {
  /* display: inline-block; */
  width: 100%;
  /* Size of the checkbox */
  height: 90%;
  background: #fff;
  border: 1px solid lightgray;
  /* Border color */
  border-radius: 5px;
  /* Optional: if you want rounded corners */
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  /* margin-right: 10px; */
  margin: 3px;
}

/* Style the label text */
.checkbox-container span {
  display: flex;
  vertical-align: middle;
  color: #0b579e;
  justify-content: center;
  align-items: center;
}

.company-logo {
  display: none;
}

.custom-file-upload {
  display: flex;
  /* padding: 6px 12px; */
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  /* padding: 10px; */
  width: 100%;
}

.upload-info-button-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-icon {
  width: 100%;
  max-width: 100px;
}

.upload-button {
  height: 25px;
  width: 132px;
  /* position: absolute; */
  font-size: 10px;
  text-align: center;
  /* top: 238px; */
  /* left: 224px; */
  display: inline-flex;
  align-items: center;
  background-color: white;
  color: #0b579e;
  border: 1px solid;
  justify-content: center;
}

.upload-info-button {
  height: 25px;
  width: 25px;
  /* position: fixed; */
  font-size: 10px;
  text-align: center;
  /* top: 130px; */
  /* left: 45px; */
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  color: lightgrey;
  justify-content: center;
}

.upload-info-button:hover {
  background-color: #0b579e;
  border-radius: 10px;
}

.input-name {
  margin-top: 20px;
}

.unregisteredInstallationInfoContainer {
  margin: 10px;
  text-align: center;
}

.unregisteredInstallationForm {
  width: 350px;
  border-radius: 8px;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px #111118;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  background-size: cover;
}

.cancel-button {
  background-color: white;
  color: #0b579e;
  border: 1px solid;
}

.companyLogoContainer {
  display: flex;
  justify-content: center;
  /* margin: 10px 0px; */
}

.menuBackground.slide-in {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: right;
  background-color: rgba(19, 39, 58, 0.85);
  z-index: 1000;
}

.menuContainer {
  width: 0;
  height: 100%;
  background-color: #0b579e;
  color: white;
  position: absolute;
  right: -100%;
  left: 100%;
  transition: 0.5s ease;
  overflow: hidden;
  bottom: 0;
  /* transform: translateX(-100%); */
}

.menuContainer.slide-in {
  left: 50%;
  width: 50%;
}

.menuContainer.slide-out {
  /* transform: translateX(-100%); */
}

.menuHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.menuContentContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.menuContentContainer div {
  margin: 2px 0px;
}

.menuContentContainer a {
  color: white !important;
  text-decoration: none;
}

.menuContentContainer a:hover {
  text-decoration: underline;
}

.menuFooterContainer {
  margin: 10px;
  padding-top: 60px;
  position: absolute;
  bottom: 0px;
}

.menuFooterContent {
  font-size: 10px;
}

.menuLogOutLink {
  display: flex;
  justify-content: left;
}

.menuLogOutLink button {
  /* text-decoration: underline; */
  font-size: medium;
  font-weight: 400;
  padding: 0px !important;
}

.menuLogOutLink button:hover {
  text-decoration: underline;
}

.prompt {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.languageIcon {
  width: 30px;
  height: 20px;
  margin-left: 5px;
}

.options {
  position: fixed;
  display: flex;
}

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.inspectionStatusIcon {
  margin-right: 10px;
}

.profileFormContainer {
  padding: 20px;
  color: #13273a;
}

.profileHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileHeader {
  font-size: larger;
  font-weight: 500;
}

.profileEditBtn {
  background-color: transparent;
  color: #13273a;
  font-weight: 500;
}

.profileUserInfoContainer {
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}

.profileUserCompanyLogoContainer {
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  background: linear-gradient(90deg, rgba(11, 87, 158, 1) 0%, rgba(19, 40, 59, 1) 100%);
  border-radius: 5px;
  padding: 10px;
}

.profileUserCompanyLogo {
  min-width: 50%;
}

.profileUserRoleContainer {
  display: flex;
  font-size: small;
  margin: 10px 0px;
}

.profileUserContactInfo {
  display: flex;
}

.profileUserEmailContainer {
  margin-left: 50px;
}

.profileUserPhoneLabel,
.profileUserEmailLabel {
  font-size: small;
}

.profileUserPhone,
.profileUserEmail {
  font-size: small;
  font-weight: 500;
}

.profileLinksContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.profileLink {
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 7px 24px #37474f4d;
  margin: 5px 0px;
  padding: 5px;
  /* justify-content: center; */
  align-items: center;
}

.profileLinkIconContainer {
  background: linear-gradient(90deg, rgba(11, 87, 158, 1) 0%, rgba(19, 40, 59, 1) 100%);
  width: 35px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 3px;
  margin-right: 30px;
}

.profileLinkIcon {
  color: white;
  border: 1px solid;
  padding: 2px;
}

.editUserCancelBtn {
  margin-top: 20px;
}

.installationNotCompleted {
  margin-top: 100px;
  width: 80%;
  display: flex;
  justify-content: space-around;
  color: red;
  align-items: center;
}

.mapContainer {
  /* margin: 20px 0px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mapHeader {
  font-size: larger;
  margin: 10px 0px;
}

.mapSubHeader {
  font-size: small;
  margin: 10px 0px;
}

.mapBtnGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
}

.update-position {
  margin-bottom: 20px;
}

.box-shadow {
  box-shadow: 0px 7px 24px #37474f4d;
}

.user-input-dropdown__control {
  border-color: #13273a !important;
}

.user-input-dropdown__placeholder {
  color: #13273a !important;
}

.user-input-dropdown__indicator-separator {
  width: 0 !important;
}

.user-input-dropdown__indicator {
  color: #13273a !important;
}

.userRolesContent {
  padding: 20px;
}

.userRolesListHeader {
  font-size: larger;
  text-align: center;
  font-weight: 500;
}

.userRolesListInfo {
  font-size: small;
}

.userRolesListHeaderContainer {
  margin-bottom: 30px;
}

.user-roles-table-header {
  font-size: x-small;
  font-weight: 700;
  display: flex;
  border-top: 1px solid #13273a;
  padding: 10px;
}

.user-roles-list-table-container {
  padding: 10px;
}

.user-roles-list-table-header-name {
  width: 100%;
}

.user-roles-list-table-header-role {
  width: 55%;
}

.user-roles-list-table-header-edit {
  width: 25%;
}

.userRoleListRow {
  width: 100%;
  font-size: smaller;
  display: flex;
  margin: 10px 0;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  align-items: center;
}

.user-role-list-table-user {
  width: 100%;
}

.user-role-list-table-role {
  width: 35%;
}

.user-role-list-table-edit {
  width: 45%;
  display: flex;
  justify-content: end;
}

.user-role-list-table-edit-btn {
  background-color: transparent;
  color: #13273a;
  border: 1px solid;
  width: 35%;
}

.user-role-add {
  margin-top: 20px;
  border-top: 1px solid #13273a;
  padding-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
}

.user-role-add-label {
  width: 85%;
}

.user-role-add-btn {
  width: 12%;
  background-color: transparent;
  color: #13273a;
  border: 1px solid;
}

.btnGroup {
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 40px);
}

.back-button {
  border: 1px solid;
  border-color: #0b579e;
  color: #0b579e;
  background-color: white;
}

.user-input-multiple-line-message {
  text-align: center;
  padding-bottom: 20px;
}

.user-role-list-table-delete-btn {
  color:red;
  background-color: transparent;
  border: 1px solid #13273a;
  width: 35%;
  margin-left: 3px;
}

.inspectionsContent {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
}

.inspectionsHeading {
  margin-bottom: 30px;
  font-size: larger;
  font-weight: 500;
}

.inspectionRow {
  box-shadow: 0px 7px 24px #37474f4d;
  display: flex;
  width: 100%;
  justify-content: start;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
}

.inspectionsInspectionSignContainer {
  display: flex;
  width: 45%;
}

.inspectionSignMini {
  width: 70%;
}

.inspectionDate {

}

.installation-inspection-sign-container {
  width: 40%;
  margin-bottom: 5px;
}

.contactContainer {
  padding: 20px;
  background-color: #f3f3f3;
}

.contactHeading {
  font-size: larger;
  font-weight: 500;
  margin-bottom: 40px;
}

.vendorInfo {

}

.vendorName {
  font-size: large;
  font-weight: 500;
  margin-bottom: 20px;
}

.vendorContactPointsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.vendorContactPoint {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 7px 24px #37474f4d;
}

.contactSchema {
  margin-top: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 45dvh;
}

.contactSchemaInputFields {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.contactSchemaSubmitBtn {
  margin-top: 20px;
}

.vendorContactPoint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #13273a;
}

.vendorContactPointLogoContainer {
  color:#0b579e;
}

.vendorContactPointHeading {
  font-size: small;
  margin-bottom: 10px;
}

.vendorContactPointValue {
  text-align: center;
}

.guidefaqContainer {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 95dvh;
}

.faqContainer, .guideContainer {
  /* padding: 20px; */
  /* background-color: #f3f3f3; */
  font-size: larger;
  font-weight: 500;
  padding: 10px 0;
}

.faqHeading, .guideHeading {
  font-size: larger;
  font-weight: 500;
  margin-bottom: 40px;
}

.collapsibleContainer {
  padding: 10px;
}

.collapsibleHeading {
  font-size: small;
  font-weight: 700;
  border: 1px solid;
  color: #13273a;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wizard-input {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.wizard-checkbox {
  width: 50px;
}

.camera-image-preview {
  width: 100%;
}

.wizardChoiceDescriptions {
  padding: 10px;
  margin: 10px 0;
  border-top: 1px solid lightgray;
}

.dialogImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogImage img {
  width: 70%;
  max-height: 150px;
}

.neutral {
  background: transparent !important;
}

.upload-button-container {
  display: flex;
  width: 50%;
  justify-content: right;
  align-items: center;
}

.upload-info-button-container {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
}

.inspectionsTable {
  overflow-y: scroll;
  height: calc(100dvh - 260px);
}

.confirmDialogTitle {
  text-align: center;
}

.confirmDialogSubtitle {
  color: #0b579e;
  font-weight: 700;
}

.send-files .confirmDialogSubtitle {
  width: 100%;
}

.send-files .user-input-multiple-line-message {
  text-align: left;
  font-size: smaller;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.send-files .confirmDialogSubtitle {
  margin-bottom: 0px;
  border-bottom: 1px solid lightgray;
}

.guideInfo {
  font-size: medium;
  font-weight: 400;
}

.collapsibleContent {
  font-size: small;
  font-weight: 400;
  border: 1px solid;
  padding: 10px;
  color: #13273a;
  border-radius: 5px;
}

.user-registration-dropdown__single-value {
  display: flex;
  align-items: center;
}

.user-registration-dropdown__option {
  display: flex !important;
  align-items: center !important;
}

.user-login-dropdown {
  margin-top: 15px;
  width: 50%;
}

.user-login-dropdown__single-value {
  display: flex;
  align-items: center;
}

.user-login-dropdown__option {
  display: flex !important;
  align-items: center !important;
}

.installation-cancel-button {
  background-color: white;
  color: #0b579e;
  border: 1px solid;
  grid-column: 1 / -1;
}

.input-language {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}